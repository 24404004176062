'use client';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Cookies from 'js-cookie';
import {
  Box,
  Button,
  Flex,
  FormControl,
  FormLabel,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  useDisclosure,
  useToast,
} from '@chakra-ui/react';
import { colors } from '../../styles/theme';

export default function PhoneVerificationModal({ myNumber }) {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [phoneNumber, setPhoneNumber] = useState(myNumber);
  const [otp, setOtp] = useState('');
  const [isSendingOtp, setIsSendingOtp] = useState(false);
  const [isVerifyingOtp, setIsVerifyingOtp] = useState(false);
  const [notNumber, setNotNumber] = useState(false);
  const [otpSent, setOtpSent] = useState(false);
  const [cooldownTime, setCooldownTime] = useState(0);
  const toast = useToast();
  const navigate = useNavigate();

  // Send OTP API call
  const handleSendOtp = async () => {
    if (cooldownTime > 0) return; // Prevent sending OTP if cooldown is active
    setIsSendingOtp(true);
    try {
      const token = `Bearer ${Cookies.get('Token')}`;

      const response = await fetch(
        `${process.env.REACT_APP_BACKEND_URL}/api/v1/me/send_otp?phone_number=${phoneNumber}`,
        {
          method: 'GET',
          headers: {
            Authorization: token,
          },
        }
      );

      if (response.ok) {
        setOtpSent(true);
        setCooldownTime(300); // Start 5-minute countdown
        toast({
          title: 'OTP Sent',
          description: 'A verification code has been sent to your phone.',
          status: 'success',
          duration: 5000,
          isClosable: true,
        });
      } else {
        const error = await response.json();
        throw new Error(error.message || 'Failed to send OTP.');
      }
    } catch (error) {
      toast({
        title: 'Error',
        description: error.message,
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
    } finally {
      setIsSendingOtp(false);
    }
  };

  // Countdown logic
  useEffect(() => {
    let timer;
    if (cooldownTime > 0) {
      timer = setInterval(() => {
        setCooldownTime((prev) => prev - 1);
      }, 1000);
    }

    return () => clearInterval(timer);
  }, [cooldownTime]);

  // Verify OTP API call
  const handleVerifyOtp = async () => {
    setIsVerifyingOtp(true);
    try {
      const token = `Bearer ${Cookies.get('Token')}`;
      const response = await fetch(
        `${process.env.REACT_APP_BACKEND_URL}/api/v1/me/verify_phone_number`,
        {
          method: 'PUT',
          headers: {
            Authorization: token,
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ otp }),
        }
      );

      if (response.ok) {
        toast({
          title: 'Phone Verified',
          description: 'Your phone number has been successfully verified.',
          status: 'success',
          duration: 5000,
          isClosable: true,
        });
        onClose();
      } else {
        const error = await response.json();
        throw new Error(error.message || 'Failed to verify OTP.');
      }
    } catch (error) {
      toast({
        title: 'Error',
        description: error.message,
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
    } finally {
      setIsVerifyingOtp(false);
    }
  };

  useEffect(() => {
    onOpen();
  }, []);

  const formatTime = (seconds) => {
    const minutes = Math.floor(seconds / 60);
    const secs = seconds % 60;
    return `${minutes}:${secs < 10 ? '0' : ''}${secs}`;
  };

  return (
    <Box>
      {/* Modal */}
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Verify Phone Number</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Text>Verify Your Phone Number : {phoneNumber}</Text>
            <Text>
              Not your number?{' '}
              <Button onClick={() => setNotNumber(true)} color={colors.primaryColor}>
                Change Number
              </Button>
            </Text>

            {notNumber && (
              <FormControl id="phone-number" mb={4}>
                <FormLabel>Phone Number</FormLabel>
                <Input
                  type="tel"
                  value={phoneNumber}
                  onChange={(e) => setPhoneNumber(e.target.value)}
                  placeholder="Enter your phone number"
                  isDisabled={isSendingOtp || isVerifyingOtp}
                />
              </FormControl>
            )}

            {otpSent && (
              <FormControl id="otp" mb={4}>
                <FormLabel>OTP</FormLabel>
                <Input
                  type="text"
                  value={otp}
                  onChange={(e) => setOtp(e.target.value)}
                  placeholder="Enter the OTP sent to your phone"
                  isDisabled={isSendingOtp || isVerifyingOtp}
                />
              </FormControl>
            )}
          </ModalBody>

          <ModalFooter>
            <Flex justifyContent="space-between" width="100%">
              <Button
                colorScheme="blue"
                onClick={handleSendOtp}
                isLoading={isSendingOtp}
                isDisabled={!phoneNumber || phoneNumber.length !== 11 || cooldownTime > 0}
              >
                {cooldownTime > 0 ? `Resend OTP in ${formatTime(cooldownTime)}` : 'Send OTP'}
              </Button>
              <Button
                colorScheme="green"
                onClick={handleVerifyOtp}
                isLoading={isVerifyingOtp}
                isDisabled={!otp || otp.length !== 6}
              >
                Verify OTP
              </Button>
            </Flex>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Box>
  );
}
